<template>
    <div class="main-content">
        <div class="userInfo py-5 bg-white">
          
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ChatPage'
    }
</script>
