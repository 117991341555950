import { render, staticRenderFns } from "./notification.vue?vue&type=template&id=6911e344&scoped=true&"
import script from "./notification.vue?vue&type=script&lang=js&"
export * from "./notification.vue?vue&type=script&lang=js&"
import style0 from "./notification.vue?vue&type=style&index=0&id=6911e344&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6911e344",
  null
  
)

export default component.exports