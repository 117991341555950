<template>
  <div class="ourServices">
    <!-- <appNavInner /> -->

    <div class="page-content">
      <div class="container">
        <div class="content">
          <div class="Consulting LearnWithUs allCons">
            <span class="arabi-mark">صناعة المحتوى</span>
                                            <a  class="btn" style="cursor:pointer;background-color:#dc3545;color:white !important;margin-left:auto;width:10%;display:block;"  v-clipboard:copy="info.link_to_copy"
                                v-clipboard:success="onCopyall"
                                v-clipboard:error="onErrorAll" >نسخ الرابط </a>
            <div class="container">
              <div class="user-info">

                <div class="img-box">
                  <img :src="info.image" alt="" />
                </div>

                <h3>{{ info.name }}</h3>
                <p v-if="info.pio">{{ info.pio }}</p>
              </div>
              <div class="row">
                <div class="col-lg-11 m-auto">
                  <div
                    v-if="loading"
                    class="d-flex justify-content-center align-items-center"
                    style="margin-top: 40px"
                  >
                    <span
                      class="d-block"
                      style="
                        font-weight: 700;
                        font-size: 26px;
                        margin-top: -15px;
                        margin-left: 18px;
                      "
                    >
                      جاري التحميل
                    </span>
                    <div class="">
                      <pulse-loader :color="color" :size="size"></pulse-loader>
                    </div>
                  </div>
                  <div v-else class="row justify-content-center">
                    <div
                      class="col-lg-4 col-md-6 col-sm-6 col-12"
                      v-for="item in list"
                      :key="item.id"
                    >
                      <div
                        class="consult-item"
                        :style="{
                          'border-top-color': item.color + '!important',
                        }"
                      >
                        <div class="itemHead"></div>
                        <router-link style="text-decoration:none;" :to="`/registration/${item.mention}/${item.url}`" class="">

                        <div
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <h3>{{ item.title
                              .substr(0, 22) + '...' }}</h3>
                          <i class="el-icon-caret-left"></i>
                        </div>
                        <p
                          v-html="
                            item.description
                              .replace(/<\/?[^>]+>/gi, '')
                              .substr(0, 32) + '...'
                          "
                        ></p>
                        <!-- <span> {{ item.type.title }} </span> -->
                          <div class="cons-marketing">
                             <div class="item">
                            <img src="../assets/zoom.png" alt="" />
                            <span>{{ item.place.title }}</span>
                          </div>

                          <p>
                            <span>{{ item.minutes || 0 }} دقيقة</span>
                          </p>
                          </div>
                        </router-link>

                        <el-divider></el-divider>

                        <div class="copyLink">
                          <div class="item">
                            <i class="el-icon-document-copy"></i>

                                        <a  style="cursor:pointer"  v-clipboard:copy="item.url_for_this_cons"
                                v-clipboard:success="onCopy"
                                v-clipboard:error="onError" >نسخ الرابط </a>
                          </div>

                          <div class="item">
                            <el-button type="success" @click="dialogVisible2 = true" >مشاركة</el-button>
                          </div>

                               <el-dialog
                              title="انقر للمشاركة"
                              :visible.sync="dialogVisible2"
                              width="30%"
                              :before-close="handleClose">
                              <div class="d-flex justify-content-center">
                                   <ShareNetwork
                                    network="facebook"
                                    :url="item.url_for_this_cons"
                                    :title="item.title"
                                    :description="item.description"
                                    quote="The hot reload is so fast it\'s near instant. - Evan You"
                                    hashtags="arabicreators"
                                  >
                                    <i class="fa-brands fa-facebook fa-3x m-2" style="color:#1877F2;"></i>
                                  </ShareNetwork>

                                   <ShareNetwork
                                    network="twitter"
                                    :url="item.url_for_this_cons"
                                    :title="item.title"
                                    :description="item.description"
                                    quote="The hot reload is so fast it\'s near instant. - Evan You"
                                    hashtags="arabicreators"
                                  >
                                    <i class="fa-brands fa-twitter fa-3x m-2" style="color:#1DA1F2;"></i>
                                  </ShareNetwork>

                                   <ShareNetwork
                                    network="whatsapp"
                                    :url="item.url_for_this_cons"
                                    :title="item.title"
                                    :description="item.description"
                                    quote="The hot reload is so fast it\'s near instant. - Evan You"
                                    hashtags="arabicreators"
                                  >
                                    <i class="fa-brands fa-whatsapp fa-3x m-2" style="color:#25D366;"></i>
                                  </ShareNetwork>

                                   <ShareNetwork
                                    network="Telegram"
                                    :url="item.url_for_this_cons"
                                    :title="item.title"
                                    :description="item.description"
                                    quote="The hot reload is so fast it\'s near instant. - Evan You"
                                    hashtags="arabicreators"
                                  >
                                    <i class="fa-brands fa-telegram fa-3x m-2" style="color:#229ED9;"></i>
                                  </ShareNetwork>

                                   <ShareNetwork
                                    network="Email"
                                    :url="item.url_for_this_cons"
                                    :title="item.title"
                                    :description="item.description"
                                    quote="The hot reload is so fast it\'s near instant. - Evan You"
                                    hashtags="arabicreators"
                                  >
                                    <i class="fa-solid fa-envelope fa-3x m-2 " style="color:#34465D;"></i>
                                  </ShareNetwork>

                              </div>
                             
                            </el-dialog>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p v-if="list.length == 0">
                لا يوجد محتوى <router-link to="/">اذهب للرئيسية</router-link>
              </p>


              



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import Vue from "vue";
import appNavInner from "@/components/navComponentInner.vue";
import breadcrumb from "@/components/breadcrumb.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "ourServices",
  components: { appNavInner, breadcrumb, PulseLoader },

  data() {
    return {
      dialogVisible2: false,
      pagination: null,
      list: null,
      catge: undefined,
      label: "انشئ استشارة",
      blogName: "الاستشارات",
      link: "",
      title: " نخبة من صناع المحتوى والمستشارين في عدة مجالات",
      loading: true,
      search: "",
      searchResult: undefined,
      BlogIds: [],
      profile: "",
      info:null
    };
  },

  

  methods: {


        onCopyall: function (e) {
      // alert("You just copied: " + e.text);
                  this.$message({
              message: "تم نسخ رابط الاستشارات بنجاح",
              type: "success",
            });
    },
    onErrorAll: function (e) {
      this.$message.error("فشل نسخ الرابط");
    },


    onCopy: function (e) {
      // alert("You just copied: " + e.text);
                  this.$message({
              message: "تم نسخ رابط الاستشارة بنجاح",
              type: "success",
            });
    },
    onError: function (e) {
      this.$message.error("فشل نسخ الرابط");
    },
    getID(event) {
      if (event.target.checked) {
        this.BlogIds.push(event.target.id);
      } else {
        const id = event.target.id;
        for (let data of this.BlogIds) {
          if (data === id) {
            const index = this.BlogIds.indexOf(data);
            this.BlogIds.splice(index, 1);
          }
        }
      }

      this.getData();
    },

    getData() {
      const pars = this.BlogIds.map((str) => {
        return parseInt(str);
      });

      const data = {
        category_id: pars,
      };

      console.log(data.category_id);

      for (let i = 0; i < data.category_id.length; i++) {
        console.log(data.category_id[i]);
      }

      let url1 = `https://dashboard.arabicreators.com/api/consultation_search?category_id=${data.category_id}`;

      Vue.axios.get(url1).then((res) => {
        const response = res.data.data;
        this.list = response.data;
        this.pagination = response.meta;
      });
    },

    getConsulting(page = 1) {
      const mention = this.$route.params.mention;

      let url = `https://dashboard.arabicreators.com/api/consultion_user/${mention}`;

      Vue.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          const response = res.data.data;
          this.info=response
          this.list = response.consultion.data;
          this.loading = false;
          console.log(response);
        });
    },

    book(mention, id) {
      this.$router.push(`/registration/${mention}/${id}`);
    },
  },

  mounted() {
    window.scrollTo(0, 0);

    // this.axios
    //   .get("https://dashboard.arabicreators.com/api/profile", {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //   })
    //   .then((r) => {
    //     this.profile = r.data.data;
    //   });

    this.getConsulting();
    
  },
};
</script>


<style lang="scss" scoped>
.page-content {
  font-family: "Tajawal", sans-serif;

  direction: rtl;
  margin-bottom: 60px;

  .allCons {
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 8%);
    border-radius: 8px;
    padding: 50px;
    position: relative;
    overflow: hidden;

        .arabi-mark {
      width: 240px;
      font-size: 20px;
      font-weight: 700;
      color: white;
      background: var(--darkColor) !important;
      position: absolute;
      left: -48px;
      top: 37px;
      padding: 10px 20px;
      transform: rotate(-43deg);
      text-align: center;
    }
  }

  .Consulting {
    margin-top: 60px;

    .user-info {
      text-align: center;
      max-width: 50%;
      margin: auto;
      margin-bottom: 50px;

      .img-box {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin: auto;
        margin-bottom: 15px;
        overflow: hidden;
        border: 1px solid rgb(143, 143, 143);

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .consult-item {
      direction: rtl;
      background-color: white;
      border-radius: 10px;
      margin: 10px;
      padding: 15px;
      border-top: var(--darkColor) solid 10px;
      text-align: right;
      margin-bottom: 20px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      .itemHead {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        .el-dropdown {
          .el-icon-s-tools {
            font-size: 20px;
          }
        }
      }

      h3 {
        margin-bottom: 10px;
        font-size: 22px;
      }

      p {
        color: rgb(159, 150, 150);
        margin-bottom: 15px;
      }

      span {
        font-weight: 600;
        margin-bottom: 8px;
      }

      .cons-marketing {
        display: flex;
        justify-content: start;
        margin-top: 10px;
        .item {
          box-shadow: unset;
          margin-left: 20%;
          img {
            margin-left: 8px;
          }

          span {
          }
        }

        p {
          font-weight: 600;
          color: black;
          margin-bottom: 0;
          span {
            font-weight: 600;
          }
        }
      }

      .copyLink {
        display: flex;
        justify-content: space-between;
        .item {
          box-shadow: unset;
          a {
            margin-right: 5px;
            color: black;
            text-decoration: unset;
          }

          .el-icon-document-copy {
          }

          .el-button {
            padding: 6px 15px;
            background-color: var(--darkColor);
          }
        }
      }
    }
  }
}
</style>


<style lang="scss">
// .el-pager {
//   display: flex !important;
//   justify-content: center;
// }
// .el-pagination .el-pager li {
//   height: 40px !important;
//   width: 40px !important;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .el-pagination {
//   direction: ltr;
//   margin-top: 40px;
//   margin-bottom: 60px;
//   .el-pager li,
//   .btn-prev,
//   .btn-next {
//     color: white;
//     border-radius: 50% !important;
//     height: 40px !important;
//     min-width: 40px !important;
//     border: 1px solid #4ebb1f;
//     background-color: white !important;
//     line-height: 40px;
//     color: #4ebb1f;
//     font-weight: 700 !important;
//     font-size: 15px;
//   }

//   .el-pager li:not(.disabled) {
//     &:hover {
//       color: #173b07 !important;
//     }
//   }

//   .el-pager li:not(.disabled).active {
//     background: linear-gradient(239.11deg, #70d644 -31.67%, #567a46 192.97%);
//     color: white;
//   }
// }
</style>



