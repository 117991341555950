<template>
  <div class="blogItem">
    <div class="container">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">الرئيسية </el-breadcrumb-item>
        <el-breadcrumb-item
          ><a href="/Consulting">الاستشارات </a></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><a href="" v-if="post && post.title">{{
            post.title
          }}</a></el-breadcrumb-item>
      </el-breadcrumb>


      <h3>{{single_booking_consultion.consultion.title}}</h3>
      <p v-html="single_booking_consultion.consultion.description"></p>
      <span> المدة: {{single_booking_consultion.consultion.minutes}}د</span>


                <div class="mt-4">
                  <span class="d-block text-end">تفاصيل الحجز</span>
            <table class="table" style="direction: rtl; background-color: #fff">
              <thead>
                <tr class="table-primary">
                  <th scope="col">اسم الاستشارة</th>
                  <th scope="col">تاريخ البداية</th>
                  <th scope="col">صاحب الاستشارة</th>
                  <th scope="col">المنصة</th>
                  <th scope="col">الحالة</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{single_booking_consultion.consultion.title}}</td>
                  <td>{{single_booking_consultion.start_at}}</td>
                  <td>{{single_booking_consultion.user_id.name}}</td>
                  <td>{{single_booking_consultion.meeting_app}}</td>
                  <td>{{single_booking_consultion.meeting_url}}</td>
                  
                </tr>

              </tbody>
            </table>
          </div>

      <!-- <h2 v-if="post && post.title">{{ post.title }}</h2> -->

      <!-- <div v-if="post" class="row">
        <div class="col-lg-8 col-md-10 col-sm-12">
          <div class="main-content">
            <div class="atical-item">
              <div class="img-box">
                <img :src="post.user_info.image" alt="" />
              </div>
              <div class="item-body">
                <p v-if="post && post.title">
                  {{ post.title }}
                </p>
              </div>
            </div>

            <div class="descripion">
              <h2>الوصف</h2>
              <p v-if="post && post.description" v-html="post.description"></p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-10 col-sm-12">
          <div class="sideBar">
            <div class="markerter-item">
              <div class="img-cover">
                <img src="../assets/back.png" alt="" />
                <img :src="post.user_info.image" class="img-man" alt="" />
              </div>

              <div class="item-body">
                <h5 v-if="post && post.user_info.name">
                  {{ post.user_info.name }}
                </h5>
                <div class="stars">
                  <el-rate
                    v-model="post.user_info.rate"
                    disabled
                    show-score
                    text-color="#ff9900"
                    score-template="{value}"
                  >
                  </el-rate>
                </div>
                <div class="info">
                  <div class="massege">
                    <i class="fa-regular fa-message"></i>
                  </div>

                  <router-link :to="`/marketers/${post.user_info.id}`">
                    <div class="user">
                      <i class="fa-regular fa-user"></i>
                    </div>
                  </router-link>

                  <div class="star">
                    <i class="fa-solid fa-star"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="share-blog">
              <p>شارك الاستشارة</p>
                           <div class="soial">
                <ShareNetwork
    network="facebook"
    :url="post.url_for_this_cons"
    :title="post.title"
    :description="post.description"
    quote="The hot reload is so fast it\'s near instant. - Evan You"
    hashtags="arabicreators,blogs"
  >
   <i class="fa-brands fa-facebook-f"></i>
</ShareNetwork>

                <ShareNetwork
    network="twitter"
    :url="post.url_for_this_cons"
    :title="post.title"
    :description="post.description"
    quote="The hot reload is so fast it\'s near instant. - Evan You"
    hashtags="arabicreators,blogs"
  >
   <i class="fa-brands fa-twitter"></i>
</ShareNetwork>


  <ShareNetwork
    network="whatsapp"
    :url="post.url_for_this_cons"
    :title="post.title"
    quote="The hot reload is so fast it\'s near instant. - Evan You"
    hashtags="arabicreators,blogs"
  >
   <i class="fa-brands fa-whatsapp"></i>
</ShareNetwork>

  <ShareNetwork
    network="Telegram"
    :url="post.url_for_this_cons"
        quote="The hot reload is so fast it\'s near instant. - Evan You"
    hashtags="arabicreators,blogs"
  >
  <i class="fa-brands fa-telegram"></i>
</ShareNetwork>

<ShareNetwork
    network="Email"
    :url="post.url_for_this_cons"
        quote="The hot reload is so fast it\'s near instant. - Evan You"
    hashtags="arabicreators,blogs"
  >
<i class="fa-solid fa-envelope"></i>
</ShareNetwork>

              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>



<script>
import appNavInner from "@/components/navComponentInner.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";

export default {
  data() {
    return {
      value: 5,
      body: null,

      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",

      editorConfig: {},
      single_booking_consultion: undefined,
      RealetedBlog: {},
      plogId: null,
      allComments: {},

      meatDesc: undefined,
      meatTitle: undefined,
      keywords: [],
    };
  },

  metaInfo() {
    return {
      title: this.meatTitle,
      titleTemplate: null,
      htmlAttrs: {
        lang: "ar",
      },
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.meatDesc,
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.keywords,
        },
      ],
    };
  },

  components: {
    appNavInner,
  },
  mounted() {
    window.scrollTo(0, 0);
    const itemID = this.$route.params.id;


    let url = `https://dashboard.arabicreators.com/api/single_booking_consultion/${itemID}`;

    axios.get(url,{
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
    }).then((res) => {
      const postData = res.data.data;
      this.single_booking_consultion = postData;
      // this.plogId = postData.id;
      // this.meatDesc = this.post.description;
      // this.meatTitle = this.post.title;

      this.post.keywords.forEach((item) => {
        this.keywords.push(item);
      });

      // console.log(postData);
    });
  },

  methods: {
    onSubmit() {},

    update() {
      this.$router.go();
    },
  },
};
</script>



<style lang="scss" scoped>
.blogItem {
  direction: rtl;
  font-family: "Tajawal", sans-serif;

  h2 {
    text-align: right;
    font-size: 40px;
    color: var(--darkColor);
    margin-bottom: 30px;
    font-weight: 600;
    position: relative;
  }

  .el-breadcrumb {
    display: flex;
    justify-content: start;
    margin-bottom: 15px;
    margin-top: 20px;

    .el-breadcrumb__item {
      font-size: 18px;
    }
  }

  .el-rate {
    text-align: start;
    margin-top: 15px;
    margin-bottom: 15px;
    direction: ltr;

    .el-rate__item {
      .el-rate__icon {
        font-size: 25px;
      }
    }
  }

  .atical-item {
    text-align: right;
    position: relative;
    width: 100%;
    height: 600px;
    margin: 10px;
    color: black;
    .img-box {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;

        &:first-child {
          z-index: 3;
        }

        &:last-child {
          z-index: 4;
        }
      }
    }

    .item-body {
      position: absolute;
      bottom: 0;
      z-index: 5;
      padding: 10px;
      p {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 5px;
        color: white;
      }
    }
  }

  .descripion {
    h2 {
      text-align: right;
    }

    p {
      text-align: right;
    }
  }

  .markerter-item {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 10px;
    border-radius: 15px;
    width: 70%;
    .img-cover {
      position: relative;
      top: 0;
      height: 200px;
      width: 100%;
      margin-bottom: 15px;

      img {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        filter: sepia(1);
      }

      .img-man {
        right: 10%;
        width: 75%;
        filter: sepia(0);
      }
    }

    .item-body {
      h5 {
        font-weight: 700;
        margin-bottom: 15px;
      }

      .stars {
        margin-bottom: 15px;
        .fa-star {
          color: gold;
          margin-left: 6px;
        }
      }

      .info {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          color: white;
          background: var(--darkColor);
          border-radius: 2px;
          padding: 6px 16px;
          margin: 8px;
        }
      }
    }

    .el-rate {
      display: flex;
      justify-content: center;
    }
  }

  .another-blogs {
    background-color: #f8f8f8;
    padding: 10px;
    width: 70%;
    margin-top: 40px;
    border-radius: 15px;
    h2 {
      font-size: 16px;
      color: #535151;
    }
    .blog-item {
      width: 100%;
      padding: 8px;
      margin-bottom: 8px;
      box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
      text-decoration: none;
      .txt {
        h3 {
          text-align: right;
          font-size: 16px;
          text-decoration: none;
        }

        .info {
          display: flex;
          justify-content: start;
          .item {
            display: flex;
            span {
              font-size: 12px;
              margin-left: 4px;
              text-decoration: none;
            }

            .fa-message {
              margin-left: 5px;
              color: var(--darkColor);
            }

            .fa-star {
              margin-left: 5px;
              color: gold;
            }

            .rate {
              color: gold;
              text-decoration: none;
            }
          }
        }
      }

      .img-item {
        width: 100%;
        height: 70px;
        border-radius: 10px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .share-blog {
    padding: 10px;
    width: 70%;
    background-color: #f8f8f8;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    margin-top: 30px;
    border-radius: 15px;
    p {
      text-align: right;
      font-weight: 600;
      font-size: 18px;
      margin-top: 10px;
      display: block;
    }

    .soial {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
      margin-bottom: 20px;
      a {
        margin-left: 6px;

        .fa-facebook-f,
        .fa-twitter,
        .fa-instagram,
        .fa-snapchat,
        .fa-envelope,
         .fa-telegram,
         .fa-whatsapp{
          font-size: 25px;
          color: var(--darkColor);
        }
      }
    }
  }

  .comments {
    background-color: #f8f8f8;
    border-radius: 5px;
    padding: 15px;

    .el-form-item {
      .el-form-item__content {
        margin-left: unset !important;
      }
    }
    .el-button {
      width: 100%;
      background: var(--darkColor);
      height: 50px;
      margin: 30px auto;
    }

    .allComments {
      display: flex;

      .sort {
        display: flex;
      }
    }

    .Comments {
      h3 {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 20px;
      }
      .comment {
        background-color: #dfdede;
        padding: 8px;
        margin-bottom: 8px;
      }
    }
  }
}

a {
  text-decoration: none;
  color: #535151;
}
</style>

<style lang="scss">
.el-form-item__content {
  margin-left: unset !important;
}
</style>