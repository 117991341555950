var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"podcast"},[_vm._m(0),_c('div',{staticClass:"JoinPodcast"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9 col-md-12 col-sm-12 order"},[(_vm.podcastList.length > 0)?_c('carousel',{staticClass:"podcast-part",attrs:{"autoplay":false,"items":4,"dots":false,"responsive":{
            0: { items: 1, nav: false },
            600: { items: 2, nav: false },
            1000: { items: 3, nav: false },
          }}},_vm._l((_vm.podcastList),function(item){return _c('div',{key:item.id,staticClass:"podcast-item",staticStyle:{"height":"400px !important"}},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":item.image,"alt":""}})]),_c('div',{staticClass:"item-body mt-4"},[_c('h3',[_vm._v(_vm._s(item.title))]),_c('vue-plyr',[_c('audio',{attrs:{"controls":"","playsinline":""}},[_c('source',{attrs:{"src":item.link,"type":"audio/mp3"}})])])],1)])}),0):_vm._e()],1),_vm._m(1)])]),_c('div',{staticClass:"JoinPodcast pods"},[_c('div',{staticClass:"container"},[_vm._m(2),_c('div',{staticClass:"row"},_vm._l((_vm.allPodcasts),function(itemp){return _c('div',{key:itemp.id,staticClass:"col-lg-4 col-md-6 col-sm-6 col-12"},[_c('router-link',{attrs:{"to":`/Podcasts/${itemp.id}`}},[_c('div',{staticClass:"podcast-item"},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":itemp.image,"alt":""}}),_c('div',{staticClass:"time"},[_c('i',{staticClass:"fa-regular fa-clock ms-1"}),_c('span',[_vm._v(_vm._s(itemp.count_item)+" sounds")])])]),_c('div',{staticClass:"item-body"},[_c('h3',[_vm._v(_vm._s(itemp.title))]),_c('p',{domProps:{"innerHTML":_vm._s(itemp.description)}})]),_c('p',[_vm._v("انشئ بواسطة "+_vm._s(itemp.user_info.name))]),_c('div',{staticClass:"item-bottom"},[_c('div',{staticClass:"icons"},[_c('img',{attrs:{"src":require("../assets/apple.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/soundCloud.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/podcast.png"),"alt":""}})])])])])],1)}),0),_c('router-link',{staticClass:"btn-all",attrs:{"to":"/Podcasts"}},[_vm._v("الكل")])],1)]),_c('div',{staticClass:"podcastChannels"},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v("قنوات البودكاست")]),(_vm.allChannel)?_c('carousel',{staticClass:"podcast-part",attrs:{"autoplay":true,"items":3,"nav":false,"responsive":{
          0: { items: 1, nav: false },
          600: { items: 2, nav: false },
          1000: { items: 3, nav: false },
        }}},_vm._l((_vm.allChannel),function(item){return _c('div',{key:item.id,staticClass:"podcastChannel"},[_c('img',{attrs:{"src":item.image,"alt":""}}),_c('h5',{staticClass:"m-3"},[_vm._v(_vm._s(item.title))])])}),0):_vm._e()],1)]),_c('footerSec')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"innerHeader"},[_c('h2',[_vm._v("انضم الى عربي بودكاست")]),_c('p',[_vm._v(" هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة , لقد تم توليد هذا النص من مولد النص العربي ")]),_c('div',{staticClass:"soial"},[_c('i',{staticClass:"fa-brands fa-apple"}),_c('i',{staticClass:"fa-solid fa-podcast"}),_c('i',{staticClass:"fa-brands fa-soundcloud"})]),_c('div',{staticClass:"imgs"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-12 col-12"},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("../assets/pod.webp"),"alt":""}})])]),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 col-12"},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("../assets/pod2.webp"),"alt":""}})])]),_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-12 col-12"},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("../assets/pod1.webp"),"alt":""}})])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 col-md-12 col-sm-12 col-12"},[_c('div',{staticClass:"text-part"},[_c('h2',[_vm._v("انضم الى عربي بودكاست")]),_c('p',[_vm._v(" هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة , لقد تم توليد ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heading d-flex justify-content-between mb-2"},[_c('p',[_vm._v(" هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة , لقد تم توليد هذا النص من مولد النص العربي ")]),_c('h2',[_vm._v("البودكاست")])])
}]

export { render, staticRenderFns }