var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"createConsultaion edit"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"form"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"120px","label-position":"top","rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"عنوان الاستشارة ","prop":"title"}},[_c('el-input',{attrs:{"placeholder":"أدخل العنوان "},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('el-form-item',{attrs:{"label":"نوع الاستشارة ","prop":"type_id"}},[_c('el-select',{attrs:{"multiple":"","placeholder":"نوع الاستشارة "},model:{value:(_vm.form.type_id),callback:function ($$v) {_vm.$set(_vm.form, "type_id", $$v)},expression:"form.type_id"}},_vm._l((_vm.consultation_category),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.title,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"مكان الاستشارة ","prop":"place_id"}},[_c('el-select',{attrs:{"placeholder":"مكان الاستشارة "},model:{value:(_vm.form.place_id),callback:function ($$v) {_vm.$set(_vm.form, "place_id", $$v)},expression:"form.place_id"}},_vm._l((_vm.consultation_places),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.title,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"الوصف","prop":"description"}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949"},model:{value:(_vm.readonly),callback:function ($$v) {_vm.readonly=$$v},expression:"readonly"}},[_vm._v("> ")]),_c('editor',{staticStyle:{"width":"100%"},attrs:{"api-key":"lq78n5tuhbwnunb6moxdzq6d2cyx8o895bfzepnwbw3mc29e","init":{
                height: 500,
                menubar: 'false',
                plugins: [
                  'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                ],
                toolbar:
                  'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                toolbar_sticky: true,
                image_caption: true,
                toolbar_mode: 'sliding',
              },"disabled":_vm.readonly},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('span',{staticClass:"text-end d-block fw-bold mb-3"},[_vm._v("لون الاستشار ة ")]),_c('div',[_c('el-radio-group',{attrs:{"prop":"color"},model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", $$v)},expression:"form.color"}},[_c('el-radio-button',{staticStyle:{"background":"#8a0000"},attrs:{"label":"#8A0000"}}),_c('el-radio-button',{staticStyle:{"background":"#fd9010"},attrs:{"label":"#FD9010"}}),_c('el-radio-button',{staticStyle:{"background":"#00508a"},attrs:{"label":"#00508A"}}),_c('el-radio-button',{staticStyle:{"background":"#8a0074"},attrs:{"label":"#8A0074"}}),_c('el-radio-button',{staticStyle:{"background":"#67b979"},attrs:{"label":"#67B979"}}),_c('el-radio-button',{staticStyle:{"background":"#f3db04"},attrs:{"label":"#F3DB04"}}),_c('el-radio-button',{staticStyle:{"background":"#8a4200"},attrs:{"label":"#8A4200"}}),_c('el-radio-button',{staticStyle:{"background":"#f5547b"},attrs:{"label":"#F5547B"}})],1)],1),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("التالي")])],1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('h2',[_vm._v("تعديل الاستشارة")])])
}]

export { render, staticRenderFns }