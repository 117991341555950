import { render, staticRenderFns } from "./serviceItem.vue?vue&type=template&id=294fb5cb&scoped=true&"
import script from "./serviceItem.vue?vue&type=script&lang=js&"
export * from "./serviceItem.vue?vue&type=script&lang=js&"
import style0 from "./serviceItem.vue?vue&type=style&index=0&id=294fb5cb&prod&lang=scss&scoped=true&"
import style1 from "./serviceItem.vue?vue&type=style&index=1&id=294fb5cb&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "294fb5cb",
  null
  
)

export default component.exports