import { render, staticRenderFns } from "./CreateConsultationTwo.vue?vue&type=template&id=b4c9dddc&"
import script from "./CreateConsultationTwo.vue?vue&type=script&lang=js&"
export * from "./CreateConsultationTwo.vue?vue&type=script&lang=js&"
import style0 from "./CreateConsultationTwo.vue?vue&type=style&index=0&id=b4c9dddc&prod&lang=scss&"
import style1 from "./CreateConsultationTwo.vue?vue&type=style&index=1&id=b4c9dddc&prod&lang=css&"
import style2 from "./CreateConsultationTwo.vue?vue&type=style&index=2&id=b4c9dddc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports