<template>
  <div class="Cart">
    <div v-if="show" class="loaderCart">
      <img src="../assets/debit-card.png" alt="" />
      <p class="mt-3 mb-3 fw-bold fs-4">جاري التحويل الى صفحة الدفع</p>
      <pulse-loader
        :loading="loading"
        :color="color"
        :size="size"
      ></pulse-loader>
    </div>
    <div
      class="container"
    >
      <h2>استشاراتي</h2>

      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error</p>

      <div class="row">
        <div class="col-lg-4">

        </div>

        <div class="col-lg-8">
          
          <div class="mt-4">
            <table class="table" style="direction: rtl; background-color: #fff">
              <thead>
                <tr class="table-primary">
                  <th scope="col">اسم الاستشارة</th>
                  <th scope="col">تاريخ البداية</th>
                  <th scope="col">صاحب الاستشارة</th>
                  <th scope="col">المنصة</th>
                  <th scope="col">الحالة</th>
                  <th scope="col">حذف</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in my_booking_consultion " :key="item.id">
                  <td><router-link :to="`/ConsItemRegistration/${item.id}`" >{{item.consultion.title}}</router-link></td>
                  <td>{{item.start_at}}</td>
                  <td>{{item.user_id.name}}</td>
                  <td>{{item.meeting_app}}</td>
                  <td>{{item.meeting_url}}</td>
                  <td>
                    <i class="el-icon-delete" style="cursor: pointer"></i>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

          <!-- <div class="cart-item" >
            <div class="img-box">
              <img src="../assets/learn-img.png" alt="" />
            </div>

            <div class="text-item">
              <h3>اسم الخدمة</h3>
              <p>
                وصف الخدمة  وصف الخدمة  وصف الخدمة  وصف الخدمة وصف الخدمة
              </p>
              <span class="d-block">اطلب في غضون 19 ساعة 16 دقيقة</span>
              <a href="" class="d-inline-block"
                ><i class="el-icon-delete ms-2"></i>حذف</a>
            </div>

            <h2>50$</h2>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from "vue";
// import pulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  data() {
    return {
      carts: undefined,
      checkout: undefined,
      itemPrice: undefined,
      sumPrice: 0,
      itemsLength: undefined,
      show: false,
      my_booking_consultion:undefined

      //my_booking_consultion

    };
  },

  components: {},

  mounted() {

     Vue.axios
      .get("https://dashboard.arabicreators.com/api/my_booking_consultion",{
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((rr) => {
        this.my_booking_consultion = rr.data.data;


      });
  },

  methods: {

  },
};
</script>


<style lang="scss">
.loaderCart {
  .v-spinner {
    margin-top: 0 !important;
  }
}
</style>


<style lang="scss" scoped>
.loaderCart {
  width: 100%;
  height: 100%;
  position: absolute;
  padding-top: 10%;
  // top: 50%;
  // right: 50%;
  // transform: translate(0, 0);
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  z-index: 50;

  img {
    margin-bottom: 50px;
  }
}
.Cart {
  overflow: hidden;
  font-family: "Tajawal", sans-serif;
  text-align: right;

  h2 {
    padding-top: 60px;
    text-align: right;
    margin-bottom: 15px;
  }

  p {
    text-align: right;
  }

  .cart-item {
    position: relative;
    direction: rtl;
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 6px;
    margin: 15px;
    margin-right: 0;
    padding: 15px;
    background: #f7f7f7;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .img-box {
      width: 200px;
      height: 150px;
      overflow: hidden;
      margin-left: 50px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .price {
      position: absolute;
      left: 30px;
    }

    a {
      margin-top: 15px;
      color: rgb(26, 25, 25);
      text-decoration: none;
    }

    .text-item {
      text-align: right;
      margin-left: 50px;
    }
  }

  .summary {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 6px;
    padding: 15px;
    direction: rtl;
    h2 {
      margin-bottom: 15px;
      font-size: 20px;
      font-weight: 600;
      span {
        font-size: 14px;
      }
    }

    .sum {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      h2 {
        font-size: 22px;
        font-weight: 500;
      }

      h4 {
        color: rgb(80, 80, 80);
        font-weight: 600;
        font-size: 20px;
      }
    }

    a {
      background: var(--darkColor);
      color: white;
    }
  }

  .continueShop {
    border-color: var(--darkColor);
    color: var(--darkColor);

    &:hover {
      border-color: var(--darkColor) !important;
    }
  }

  .help {
    background-color: #f7f7f7;
    padding: 20px;
    margin-top: 50px;
  }

  .innerhelp {
    display: flex;
    justify-content: space-between;
    direction: rtl;
    align-items: center;

    div {
    }
  }
}
</style>


