

<template>
  <div class="createConsultaion">
    <!-- <navbar /> -->

    <div class="content">
      <div class="container">
        <div class="header">
          <h2>تعديل استشارة</h2>
          <p>من خلال إنشاء استشارة ، ستظهر في منصة Arabi marketer</p>
        </div>

        <div class="form cons2">
          <el-form
            ref="form"
            :model="form"
            label-width="120px"
            label-position="top"
            :rules="rules"
          >





            <el-form-item class="mb-4" label="مدة الاستشارة ">
              <el-radio-group v-model="form.mints" @change="timeStepCount">
                <el-radio-button
                  label="30د"
                  style="background: #fff"
                ></el-radio-button>
                <el-radio-button
                  label="60د"
                  style="background: #fff"
                ></el-radio-button>
                <el-radio-button
                  label="90د"
                  style="background: #fff"
                ></el-radio-button>
                <el-radio-button
                  label="120د"
                  style="background: #fff"
                ></el-radio-button>
                <el-radio-button
                  label="حدد"
                  style="background: #fff"
                ></el-radio-button>
              </el-radio-group>

              <div
                class="w-25 d-flex align-items-center addTime mt-3 mb-4"
                v-if="addTime"
              >
                <el-time-select
                  v-model="value5"
                  @change="chooseVal"
                  :picker-options="{
                    start: '0:01',
                    step: '00:1',
                    end: '02:00',
                  }"
                  placeholder="حدد مدة"
                >
                </el-time-select>
              </div>

              <!--  <el-select v-model="form.region" placeholder="اختيار وقت اخر  ">
                <el-option label="تسويقية" value="تسويقية "></el-option>
                <el-option label="تسويقية" value="تسويقية"></el-option>
              </el-select>
            </el-form-item> -->

              <!-- <div class="row">
              <div class="col-6">
                <el-form-item label="الساعات" prop="time">
                  <el-input
                    class="m-2"
                    v-model="form.hour"
                    placeholder=""
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-6">
                <el-form-item label="الدقائق" prop="time">
                  <el-select
                    class="m-2"
                    v-model="form.mints"
                    placeholder="دقائق "
                  >
                    <el-option label="10" value="10"></el-option>
                    <el-option label="20" value="20"></el-option>
                  </el-select>
              </div>-->
            </el-form-item>

            <div class="slecetDates">
              <span
                style="margin-top: 30px"
                class="text-end d-block fw-bold mb-4 mt-4"
              >
                حدد المواعيد
              </span>
              <div class="week mt-3 mb-4">
                <!-- <el-date-picker
                v-model="week"
                type="week"
                format="Week WW"
                value-format="yyyy-MM-dd"
                firstDayOfWeek="1"
                placeholder="اختر اسبوع"
              >
              </el-date-picker> -->
               
               <el-form-item class="" prop="url">
                 <el-date-picker 
                  v-model="week"
                  type="daterange"
                  range-separator="To"
                  start-placeholder="من"
                  end-placeholder="الى"
                  value-format="yyyy-MM-dd"
                  align="right"
                >
                </el-date-picker>
               </el-form-item>

              </div>

              <div
                style="position: relative"
                v-for="item in satrday"
                :key="item"
                class="mt-3"
              >
                <div class="">
                  <div class="row mb-2">
                    <div class="col-2">
                      <div class="d-flex align-items-center h-100">
                        <el-switch
                          class="mb-0 ms-2"
                          v-model="item.status"
                          @change="changeTimeState"
                        >
                        </el-switch>
                        <span> السبت </span>
                      </div>
                    </div>
                    <div class="col-4">
                      <el-time-select
                        placeholder="وقت البداية"
                        v-model="item.startSat"
                        :disabled="!item.status"
                        :picker-options="{
                          start: '08:00',
                          step: timeStep,
                          end: '20:00',
                        }"
                      >
                      </el-time-select>
                    </div>

                    <div class="col-4">
                      <el-time-select
                        placeholder="وقت النهاية"
                        v-model="item.endSat"
                        :disabled="!item.status"
                        :picker-options="{
                          start: '08:00',
                          step: timeStep,
                          end: '20:00',
                          minTime: item.startSat,
                        }"
                      >
                      </el-time-select>
                    </div>

                    <div
                      class="d-flex align-items-center col-1"
                      style="padding-right: 0px"
                    >
                      <div v-if="item.delete">
                        <div
                          class=""
                          style="cursor: pointer"
                          @click.prevent="reomveSat(item)"
                        >
                          <i
                            class="el-icon-circle-close"
                            style="font-size: 25px"
                          ></i>
                        </div>
                      </div>

                      <div v-if="item.status && item.add">
                        <div class="" style="cursor: pointer" @click="addSat">
                          <i
                            class="el-icon-circle-plus-outline"
                            style="font-size: 25px"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="position: relative"
                v-for="item in Sunday"
                :key="item"
              >
                <div class="">
                  <div class="row mb-2">
                    <div class="col-2">
                      <div class="d-flex align-items-center h-100">
                        <el-switch
                          class="mb-0 ms-2"
                          v-model="item.status"
                          @change="changeTimeState"
                        >
                        </el-switch>
                        <span> الاحد </span>
                      </div>
                    </div>
                    <div class="col-4">
                      <el-time-select
                        placeholder="وقت البداية"
                        v-model="item.startSat"
                        :disabled="!item.status"
                        :picker-options="{
                          start: '08:00',
                          step: timeStep,
                          end: '20:00',
                        }"
                      >
                      </el-time-select>
                    </div>

                    <div class="col-4">
                      <el-time-select
                        placeholder="وقت النهاية"
                        v-model="item.endSat"
                        :disabled="!item.status"
                        :picker-options="{
                          start: '08:00',
                          step: timeStep,
                          end: '20:00',
                          minTime: item.startSat,
                        }"
                      >
                      </el-time-select>
                    </div>
                    <!-- <div class="col-2 d-flex align-items-center" >
                 <div class="" style="cursor:pointer;" @click.prevent="reomveSat(item)"><i class="el-icon-circle-close" style="font-size:25px;"></i></div>

                </div> -->

                    <div
                      class="d-flex align-items-center col-1"
                      style="padding-right: 0px"
                    >
                      <div v-if="item.delete">
                        <div
                          class=""
                          style="cursor: pointer"
                          @click.prevent="reomveSun(item)"
                        >
                          <i
                            class="el-icon-circle-close"
                            style="font-size: 25px"
                          ></i>
                        </div>
                      </div>

                      <div v-if="item.status && item.add">
                        <div
                          class=""
                          style="cursor: pointer"
                          @click="addSunday"
                        >
                          <i
                            class="el-icon-circle-plus-outline"
                            style="font-size: 25px"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="position: relative"
                v-for="item in Monday"
                :key="item"
              >
                <div class="">
                  <div class="row mb-2">
                    <div class="col-2">
                      <div class="d-flex align-items-center h-100">
                        <el-switch
                          class="mb-0 ms-2"
                          v-model="item.status"
                          @change="changeTimeState"
                        >
                        </el-switch>
                        <span> الاثنين </span>
                      </div>
                    </div>
                    <div class="col-4">
                      <el-time-select
                        placeholder="وقت البداية"
                        v-model="item.startSat"
                        :disabled="!item.status"
                        :picker-options="{
                          start: '08:00',
                          step: timeStep,
                          end: '20:00',
                        }"
                      >
                      </el-time-select>
                    </div>

                    <div class="col-4">
                      <el-time-select
                        placeholder="وقت النهاية"
                        v-model="item.endSat"
                        :disabled="!item.status"
                        :picker-options="{
                          start: '08:00',
                          step: timeStep,
                          end: '20:00',
                          minTime: item.startSat,
                        }"
                      >
                      </el-time-select>
                    </div>

                    <div
                      class="d-flex align-items-center col-1"
                      style="padding-right: 0px"
                    >
                      <div v-if="item.delete">
                        <div
                          class=""
                          style="cursor: pointer"
                          @click.prevent="reomveMon(item)"
                        >
                          <i
                            class="el-icon-circle-close"
                            style="font-size: 25px"
                          ></i>
                        </div>
                      </div>

                      <div v-if="item.status && item.add">
                        <div
                          class=""
                          style="cursor: pointer"
                          @click="addMonday"
                        >
                          <i
                            class="el-icon-circle-plus-outline"
                            style="font-size: 25px"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="position: relative"
                v-for="item in Tuesday"
                :key="item"
              >
                <div class="">
                  <div class="row mb-2">
                    <div class="col-2">
                      <div class="d-flex align-items-center h-100">
                        <el-switch
                          class="mb-0 ms-2"
                          v-model="item.status"
                          @change="changeTimeState"
                        >
                        </el-switch>
                        <span> الثلاثاء </span>
                      </div>
                    </div>
                    <div class="col-4">
                      <el-time-select
                        placeholder="وقت البداية"
                        v-model="item.startSat"
                        :disabled="!item.status"
                        :picker-options="{
                          start: '08:00',
                          step: timeStep,
                          end: '20:00',
                        }"
                      >
                      </el-time-select>
                    </div>

                    <div class="col-4">
                      <el-time-select
                        placeholder="وقت النهاية"
                        v-model="item.endSat"
                        :disabled="!item.status"
                        :picker-options="{
                          start: '08:00',
                          step: timeStep,
                          end: '20:00',
                          minTime: item.startSat,
                        }"
                      >
                      </el-time-select>
                    </div>
                    <!-- <div class="col-2 d-flex align-items-center" >
                 <div class="" style="cursor:pointer;" @click.prevent="reomveSat(item)"><i class="el-icon-circle-close" style="font-size:25px;"></i></div>

                </div> -->

                    <div
                      class="d-flex align-items-center col-1"
                      style="padding-right: 0px"
                    >
                      <div v-if="item.delete">
                        <div
                          class=""
                          style="cursor: pointer"
                          @click.prevent="reomveTue(item)"
                        >
                          <i
                            class="el-icon-circle-close"
                            style="font-size: 25px"
                          ></i>
                        </div>
                      </div>

                      <div v-if="item.status && item.add">
                        <div
                          class=""
                          style="cursor: pointer"
                          @click="addTuesday"
                        >
                          <i
                            class="el-icon-circle-plus-outline"
                            style="font-size: 25px"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="position: relative"
                v-for="item in Wednesday"
                :key="item"
              >
                <div class="">
                  <div class="row mb-2">
                    <div class="col-2">
                      <div class="d-flex align-items-center h-100">
                        <el-switch
                          class="mb-0 ms-2"
                          v-model="item.status"
                          @change="changeTimeState"
                        >
                        </el-switch>
                        <span> الاربعاء </span>
                      </div>
                    </div>
                    <div class="col-4">
                      <el-time-select
                        placeholder="وقت البداية"
                        v-model="item.startSat"
                        :disabled="!item.status"
                        :picker-options="{
                          start: '08:00',
                          step: timeStep,
                          end: '20:00',
                        }"
                      >
                      </el-time-select>
                    </div>

                    <div class="col-4">
                      <el-time-select
                        placeholder="وقت النهاية"
                        v-model="item.endSat"
                        :disabled="!item.status"
                        :picker-options="{
                          start: '08:00',
                          step: timeStep,
                          end: '20:00',
                          minTime: item.startSat,
                        }"
                      >
                      </el-time-select>
                    </div>
                    <!-- <div class="col-2 d-flex align-items-center" >
                 <div class="" style="cursor:pointer;" @click.prevent="reomveSat(item)"><i class="el-icon-circle-close" style="font-size:25px;"></i></div>

                </div> -->

                    <div
                      class="d-flex align-items-center col-1"
                      style="padding-right: 0px"
                    >
                      <div v-if="item.delete">
                        <div
                          class=""
                          style="cursor: pointer"
                          @click.prevent="reomveWed(item)"
                        >
                          <i
                            class="el-icon-circle-close"
                            style="font-size: 25px"
                          ></i>
                        </div>
                      </div>

                      <div v-if="item.status && item.add">
                        <div
                          class=""
                          style="cursor: pointer"
                          @click="addWednesday"
                        >
                          <i
                            class="el-icon-circle-plus-outline"
                            style="font-size: 25px"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="position: relative"
                v-for="item in Thursday"
                :key="item"
              >
                <div class="">
                  <div class="row mb-2">
                    <div class="col-2">
                      <div class="d-flex align-items-center h-100">
                        <el-switch
                          class="mb-0 ms-2"
                          v-model="item.status"
                          @change="changeTimeState"
                        >
                        </el-switch>
                        <span> الخميس </span>
                      </div>
                    </div>
                    <div class="col-4">
                      <el-time-select
                        placeholder="وقت البداية"
                        v-model="item.startSat"
                        :disabled="!item.status"
                        :picker-options="{
                          start: '08:00',
                          step: timeStep,
                          end: '20:00',
                        }"
                      >
                      </el-time-select>
                    </div>

                    <div class="col-4">
                      <el-time-select
                        placeholder="وقت النهاية"
                        v-model="item.endSat"
                        :disabled="!item.status"
                        :picker-options="{
                          start: '08:00',
                          step: timeStep,
                          end: '20:00',
                          minTime: item.startSat,
                        }"
                      >
                      </el-time-select>
                    </div>

                    <div
                      class="d-flex align-items-center col-1"
                      style="padding-right: 0px"
                    >
                      <div
                        v-if="item.delete"

                      >
                        <div
                          class=""
                          style="cursor: pointer"
                          @click.prevent="reomveThur(item)"
                        >
                          <i
                            class="el-icon-circle-close"
                            style="font-size: 25px"
                          ></i>
                        </div>
                      </div>

                      <div
                        v-if="item.status && item.add"

                      >
                        <div
                          class=""
                          style="cursor: pointer"
                          @click="addThursday"
                        >
                          <i
                            class="el-icon-circle-plus-outline"
                            style="font-size: 25px"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style="position: relative"
                v-for="item in Friday"
                :key="item"
              >
                <div class="">
                  <div class="row mb-2">
                    <div class="col-2">
                      <div class="d-flex align-items-center h-100">
                        <el-switch
                          class="mb-0 ms-2"
                          v-model="item.status"
                          @change="changeTimeState"
                        >
                        </el-switch>
                        <span> الجمعة </span>
                      </div>
                    </div>
                    <div class="col-4">
                      <el-time-select
                        placeholder="وقت البداية"
                        v-model="item.startSat"
                        :disabled="!item.status"
                        :picker-options="{
                          start: '08:30',
                          step: timeStep,
                          end: '18:30',
                        }"
                      >
                      </el-time-select>
                    </div>

                    <div class="col-4">
                      <el-time-select
                        placeholder="وقت النهاية"
                        v-model="item.endSat"
                        :disabled="!item.status"
                        :picker-options="{
                          start: '08:30',
                          step: timeStep,
                          end: '18:30',
                          minTime: item.startSat,
                        }"
                      >
                      </el-time-select>
                    </div>
                    <!-- <div class="col-2 d-flex align-items-center" >
                 <div class="" style="cursor:pointer;" @click.prevent="reomveSat(item)"><i class="el-icon-circle-close" style="font-size:25px;"></i></div>

                </div> -->

                    <div
                      class="d-flex align-items-center col-1"
                      style="padding-right: 0px"
                    >
                      <div
                        v-if="item.delete"

                      >
                        <div
                          class=""
                          style="cursor: pointer"
                          @click.prevent="reomveFri(item)"
                        >
                          <i
                            class="el-icon-circle-close"
                            style="font-size: 25px"
                          ></i>
                        </div>
                      </div>

                      <div
                        v-if="item.status && item.add"

                      >
                        <div
                          class=""
                          style="cursor: pointer"
                          @click="addFriday"
                        >
                          <i
                            class="el-icon-circle-plus-outline"
                            style="font-size: 25px"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-6">
                <el-form-item label="تاريخ بداية الاستشارة " prop="start_date">
                  <el-date-picker
                    v-model="form.start_date"
                    format="yyyy/MM/dd"
                    value-format="yyyy-MM-dd"
                  >
                    type="date">
                  </el-date-picker>
                </el-form-item>
              </div>

              <div class="col-6">
                <el-form-item label="تاريخ نهاية الاستشارة " prop="start_date">
                  <el-date-picker
                    v-model="form.end_date"
                    format="yyyy/MM/dd"
                    value-format="yyyy-MM-dd"
                  >
                    type="date">
                  </el-date-picker>
                </el-form-item>
              </div>
            </div> -->

            <!-- <el-form-item label="أوقات الاستشارة ">
              <el-select class="m-2" v-model="form.region" placeholder=" ">
                <el-option label="zoom" value=""></el-option>
                <el-option label="zoom" value=""></el-option>
              </el-select>
              <el-time-select
                class="m-2"
                v-model="value"
                :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '18:30',
                }"
                placeholder="00:00"
              >
              </el-time-select>

              <el-time-select
                class="m-2"
                v-model="value"
                :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '18:30',
                }"
                placeholder="00:00"
              >
              </el-time-select>
            </el-form-item>
            <el-button class="add-more">اضافة اخر +</el-button> -->

            <span
              style="margin-top: 30px"
              class="text-end d-block fw-bold mb-3 mt-4"
              >سعر الاستشارة
            </span>

            <div class="slider">
              <el-slider
                v-model="form.price"
                :max="200"
                :format-tooltip="formatTooltip"
                tooltip-class="toll"
                :show-tooltip="true"
                show-input="true"
                :marks="marks"
                prop=""
              >
                <el-slider v-model="form.price"></el-slider>
              </el-slider>
            </div>

            <el-form-item label="طريقة الدفع  " prop="url" class="mb-4">
              <el-select v-model="form.payment_id" placeholder="فيزا ">
                <el-option
                  v-for="item in consultation_payments"
                  :key="item.id"
                  :value="item.id"
                  :label="item.title"
                  style="background-image: url()"
                >
                  <span style="float: left">{{ item.title }}</span>
                  <span style="float: right; color: #8492a6"
                    ><img style="width: 40px" :src="item.image" alt=""
                  /></span>
                </el-option>
              </el-select>
            </el-form-item>

            <div class="link">
              <span
                style="margin-top: 30px"
                class="text-end d-block fw-bold mb-3 mt-4"
              >
                الرابط
              </span>
              <div class="d-flex align-items-center">
                <div class="w-75">
                  <el-form-item prop="url" class="">
                    <el-input
                      v-model="form.url"
                      placeholder="Metting"
                      @input="checkUrl"
                    ></el-input>
                  </el-form-item>
                </div>
                <p class="w-25 me-2 fw-bold">{{ label }}</p>
              </div>
              <span class="text-end d-block text-success" v-if="urlMsg">{{
                urlMsg
              }}</span>
              <span class="text-end d-block text-danger" v-if="urlMsg2">{{
                urlMsg2
              }}</span>
            </div>

            <div class="addsTime">
               <span
                style="margin-top: 30px"
                class="text-end d-block fw-bold mb-3 mt-4"
              >
                تريد اضافة وقت قبل او بعد الاستشارة ؟
              </span>

              <el-checkbox v-model="checked" class="me-0 mt-3 mb-2"
                >قبل الاستشارة</el-checkbox
              >

              <el-form-item prop="url" class="mb-4">
                <el-select :disabled="!checked" v-model="form.beforeTime">
                  <el-option
                    v-for="item in optionsDates"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-checkbox v-model="checked2" class="me-0 mt-3 mb-2"
                >بعد الاستشارة</el-checkbox
              >

              <el-form-item prop="url" class="mb-4">
                <el-select :disabled="!checked2" v-model="form.afterTime">
                  <el-option
                    v-for="item in optionsDates"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <el-button type="primary" plain @click.prevent="postData('form')"
              >تعديل</el-button
            >
            <el-button class="prev" @click="perv">السابق</el-button>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import navbar from "@/components/navComponentInner.vue";
export default {
  components: {
    navbar,
  },
  data() {
    return {
      checked: false,
      checked2: false,
      urlMsg: "",
      urlMsg2: "",
      radio1: "red",
      value: "",
      value1: "",
      value2: "",
      form: {
        url: "",
        start_date: '',
        end_date: "",
        hour: "",
        mints: "60د",
        price: 25,
        payment_id: "",
        beforeTime: "",
        afterTime: "",
      },

      rules: {
        url: [{ required: true, message: "هذا الحقل مطلوب", trigger: "blur" }],
        time: [{ required: true, message: "هذا الحقل مطلوب", trigger: "blur" }],
        type_id: [
          { required: true, message: "هذا الحقل مطلوب", trigger: "blur" },
        ],
        start_date: [
          { required: true, message: " هذا الحقل مطلوب ", trigger: "change" },
        ],
        end_date: [
          { required: true, message: " هذا الحقل مطلوب ", trigger: "change" },
        ],
      },
      value4: [30, 60],
      marks: {
        0: "free",
        // 10:'10',
        // 20:'20',
        //         30:'30',
        // 40:'40',
        //         50:'50',
        // 60:'60',
        //         70:'70',
        // 80:'80',
        //         90:'90',
        // 100:'100',
        //         110:'110',
        // 120:'120',
        //         130:'130',
        // 140:'140',
        // 150:'150',
        // 160:'160',
        // 170:'170',
        // 180:'180',
        // 190:'190',
        200: "200",
      },
      optionsDates: [
        {
          value: 5,
          label: "5 min",
        },
        {
          value: 10,
          label: "10 min",
        },
        {
          value: 15,
          label: "15 min",
        },
        {
          value: 30,
          label: "30 min",
        },
        {
          value: 45,
          label: "45 min",
        },
        {
          value: 60,
          label: "1 hr",
        },

        {
          value: 120,
          label: "2 hr",
        },

        {
          value: 150,
          label: "2 hr 30 min",
        },

        {
          value: 180,
          label: "3 hr",
        },
      ],
      consultation_payments: null,

      startTime: "",
      endTime: "",
      day: true,
      timeState: false,

      startTime2: "",
      endTime2: "",
      day2: true,
      timeState2: false,

      startTime3: "",
      endTime3: "",
      day3: false,
      timeState3: false,

      startTime4: "",
      endTime4: "",
      day4: true,
      timeState4: false,

      startTime5: "",
      endTime5: "",
      day5: true,
      timeState5: false,

      startTime6: "",
      endTime6: "",
      day6: false,
      timeState6: false,

      startTime7: "",
      endTime7: "",
      day7: true,
      timeState7: false,

      Days: [],

      From: [],
      To: [],

      rangeValue: 0,
      label: "",

      timeStep: "00:60",

      week: "",


      satrday: [
        {
          startSat: "",
          endSat: "",
          add: true,
          delete: false,
          day: "Saturday",
          status: null,
          id: 0,
        },
      ],
      id: 1,

      Sunday: [
        {
          startSat: "",
          endSat: "",
          add: true,
          delete: false,
          day: "Sunday",
          status: null,
          id2: 0,
        },
      ],
      id2: 1,

      Monday: [
        {
          startSat: "",
          endSat: "",
          add: true,
          delete: false,
          day: "Monday",
          status: null,
          id3: 0,
        },
      ],
      id3: 1,

      Tuesday: [
        {
          startSat: "",
          endSat: "",
          add: true,
          delete: false,
          day: "Tuesday",
          status: null,
          id4: 0,
        },
      ],
      id4: 1,

      Wednesday: [
        {
          startSat: "",
          endSat: "",
          add: true,
          delete: false,
          day: "Wednesday",
          status: null,
          id5: 0,
        },
      ],
      id5: 1,

      Thursday: [
        {
          startSat: "",
          endSat: "",
          add: true,
          delete: false,
          day: "Thursday",
          status: null,
          id6: 0,
        },
      ],
      id6: 1,

      Friday: [
        {
          startSat: "",
          endSat: "",
          add: true,
          delete: false,
          day: "Friday",
          status: null,
          id7: 0,
        },
      ],
      id7: 1,

      addTime: false,
      value5: "",
      myDates: "",
      Consultation:undefined,
    };
  },

  computed: { 

  },

  mounted() {


    const itemID = localStorage.getItem('ConsMention');
    const urllink = localStorage.getItem('ConsUrl')


    let url = `https://dashboard.arabicreators.com/api/single_consultion/${itemID}/${urllink}`;

    Vue.axios.get(url,{
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
    }).then((res) => {
      const postData = res.data.data;
      this.Consultation = postData;
      this.form.price=postData.price
      this.form.mints=`${postData.minutes}د`
      this.form.payment_id=postData.payment.id
      this.form.url=postData.url
      this.week=postData.day


      
      for (let i = 0; i < postData.date.length; i++) {
        

      if(postData.date[i].day=='Saturday'){
        this.satrday[0].startSat = postData.date[i].start_at.substr(0, 5);
        this.satrday[0].endSat = postData.date[i].end_at.substr(0, 5);
        this.satrday[0].status = true;
      } else if(postData.date[i].day=='Sunday'){
        this.Sunday[0].startSat = postData.date[i].start_at.substr(0, 5);
        this.Sunday[0].endSat = postData.date[i].end_at.substr(0, 5);
        this.Sunday[0].status = true;
      }else if(postData.date[i].day=='Monday'){
        this.Monday[0].startSat = postData.date[i].start_at.substr(0, 5);
        this.Monday[0].endSat = postData.date[i].end_at.substr(0, 5);
        this.Monday[0].status = true;
      }else if(postData.date[i].day=='Tuesday'){
        this.Tuesday[0].startSat = postData.date[i].start_at.substr(0, 5);
        this.Tuesday[0].endSat = postData.date[i].end_at.substr(0, 5);
        this.Tuesday[0].status = true;
      }else if(postData.date[i].day=='Wednesday'){
        this.Wednesday[0].startSat = postData.date[i].start_at.substr(0, 5);
        this.Wednesday[0].endSat = postData.date[i].end_at.substr(0, 5);
        this.Wednesday[0].status = true;
      }else if(postData.date[i].day=='Thursday'){
        this.Thursday[0].startSat = postData.date[i].start_at.substr(0, 5);
        this.Thursday[0].endSat = postData.date[i].end_at.substr(0, 5);
        this.Thursday[0].status = false;
      }else if(postData.date[i].day=='Friday'){
        this.Friday[0].startSat = postData.date[i].start_at.substr(0, 5);
        this.Friday[0].endSat = postData.date[i].end_at.substr(0, 5);
        this.Friday[0].status = false;
      }
        
      else if( postData.date[i].day!='Saturday'){
          this.satrday[0].status = false;
      }else if( postData.date[i].day!='Sunday'){
          this.Sunday[0].status = false;
      }else if( postData.date[i].day!='Monday'){
          this.Monday[0].status = false;
      }else if( postData.date[i].day!='Tuesday'){
          this.Tuesday[0].status = false;
      }else if( postData.date[i].day!='Wednesday'){
          this.Wednesday[0].status = false;
      }else if( postData.date[i].day!='Thursday'){
          this.Thursday[0].status = false;
      }else if( postData.date[i].day!='Friday'){
          this.Friday[0].status = false;
      }

    }



      this.post.keywords.forEach((item) => {
        this.keywords.push(item);
      });

      // console.log(postData);
    });

    window.scrollTo(0, 0);

    this.label = `/arabicreators.com/${localStorage.getItem("mention")}`;

    this.label = this.label.toUpperCase();

    if(this.day3 == false) {
      this.timeState3 = true;
    } else if (this.day3 == true) {
      this.timeState3 = false;
    }

    if(this.day6 == false) {
      this.timeState6 = true;
    } else if (this.day6 == true) {
      this.timeState6 = false;
    }

    if(!localStorage.getItem("token")){
      this.$router.push("/signIn");
    }

    Vue.axios
      .get("https://dashboard.arabicreators.com/api/consultation_payments")
      .then((rr) => {
        console.log(rr);
        this.consultation_payments = rr.data.data;
      });

  
  },
  methods: {
    timeStepCount() {
      if (this.form.mints == "30د") {
        this.timeStep = "00:30";
      } else if (this.form.mints == "60د") {
        this.timeStep = "00:60";
      } else if (this.form.mints == "90د") {
        this.timeStep = "00:90";
      } else if (this.form.mints == "120د") {
        this.timeStep = "00:120";
      } else if (this.form.mints == "حدد") {
        this.addTime = true;
      }
    },

    chooseVal() {
      this.timeStep = this.value5;
      this.form.mints = this.value5;
    },

    addSat() {
      this.satrday.push({
        startSat: "",
        endSat: "",
        day: "Saturday",
        delete: true,
        add: false,
        status: true,
        id: this.id,
      });
      this.id++;

      console.log(this.satrday);
    },

    addSunday() {
      this.Sunday.push({
        startSat: "",
        endSat: "",
        delete: true,
        add: false,
        day: "Sunday",
        status: true,
        id: this.id,
      });
      this.id++;

      console.log(this.Sunday);
    },

    addMonday() {
      this.Monday.push({
        startSat: "",
        endSat: "",
        delete: true,
        add: false,
        day: "Monday",
        status: true,
        id: this.id,
      });
      this.id++;

      console.log(this.Monday);
    },

    addTuesday() {
      this.Tuesday.push({
        startSat: "",
        endSat: "",
        delete: true,
        add: false,
        day: "Tuesday",
        status: true,
        id: this.id,
      });
      this.id++;

      console.log(this.Tuesday);
    },

    addWednesday() {
      this.Wednesday.push({
        startSat: "",
        endSat: "",
        delete: true,
        add: false,
        day: "Wednesday",
        status: true,
        id: this.id,
      });
      this.id++;

      console.log(this.Wednesday);
    },

    addThursday() {
      this.Thursday.push({
        startSat: "",
        endSat: "",
        delete: true,
        add: false,
        day: "Thursday",
        status: true,
        id: this.id,
      });
      this.id++;

      console.log(this.Thursday);
    },

    addFriday() {
      this.Friday.push({
        startSat: "",
        endSat: "",
        delete: true,
        add: false,
        day: "Friday",
        status: true,
        id: this.id,
      });
      this.id++;

      console.log(this.Friday);
    },

    reomveSat(item) {
      console.log(this.satrday);
      this.satrday;
      const index = this.satrday.indexOf(item);
      if (index > -1) {
        this.satrday.splice(index, 1);
      }

      console.log(this.satrday);
    },

    reomveSat(item) {
      console.log(this.satrday);
      this.satrday;
      const index = this.satrday.indexOf(item);
      if (index > -1) {
        this.satrday.splice(index, 1);
      }

      console.log(this.satrday);
    },

    reomveSat(item) {
      console.log(this.satrday);
      this.satrday;
      const index = this.satrday.indexOf(item);
      if (index > -1) {
        this.satrday.splice(index, 1);
      }

      console.log(this.satrday);
    },

    reomveSun(item) {
      console.log(this.Sunday);
      this.Sunday;
      const index = this.Sunday.indexOf(item);
      if (index > -1) {
        this.Sunday.splice(index, 1);
      }

      console.log(this.Sunday);
    },

    reomveMon(item) {
      console.log(this.Monday);
      this.Monday;
      const index = this.Monday.indexOf(item);
      if (index > -1) {
        this.Monday.splice(index, 1);
      }

      console.log(this.Monday);
    },

    reomveTue(item) {
      console.log(this.Tuesday);
      this.Tuesday;
      const index = this.Tuesday.indexOf(item);
      if (index > -1) {
        this.Tuesday.splice(index, 1);
      }

      console.log(this.Tuesday);
    },

    reomveWed(item) {
      console.log(this.Wednesday);
      this.Wednesday;
      const index = this.Wednesday.indexOf(item);
      if (index > -1) {
        this.Wednesday.splice(index, 1);
      }

      console.log(this.Wednesday);
    },

    reomveThur(item) {
      console.log(this.Thursday);
      this.Thursday;
      const index = this.Thursday.indexOf(item);
      if (index > -1) {
        this.Thursday.splice(index, 1);
      }

      console.log(this.Thursday);
    },

    reomveFri(item) {
      console.log(this.Friday);
      this.Friday;
      const index = this.Friday.indexOf(item);
      if (index > -1) {
        this.Friday.splice(index, 1);
      }

      console.log(this.Friday);
    },

    formatTooltip(val) {
      if (val > 0 && val <= 50) {
        return `${val}$`;
      }

      if (val > 50 && val <= 90) {
        return `${val}$$`;
      }

      if (val > 90 && val <= 200) {
        return `${val}$$$`;
      }
    },

    changeTimeState() {
      if (this.day == false) {
        this.timeState = true;
      } else if (this.day == true) {
        this.timeState = false;
      }
    },

    changeTimeState2() {
      if (this.day2 == false) {
        this.timeState2 = true;
      } else if (this.day2 == true) {
        this.timeState2 = false;
      }
    },

    changeTimeState3() {
      if (this.day3 == false) {
        this.timeState3 = true;
      } else if (this.day3 == true) {
        this.timeState3 = false;
      }
    },

    changeTimeState4() {
      if (this.day4 == false) {
        this.timeState4 = true;
      } else if (this.day4 == true) {
        this.timeState4 = false;
      }
    },

    changeTimeState5() {
      if (this.day5 == false) {
        this.timeState5 = true;
      } else if (this.day5 == true) {
        this.timeState5 = false;
      }
    },

    changeTimeState6() {
      if (this.day6 == false) {
        this.timeState6 = true;
      } else if (this.day6 == true) {
        this.timeState6 = false;
      }
    },

    changeTimeState7() {
      if (this.day7 == false) {
        this.timeState7 = true;
      } else if (this.day7 == true) {
        this.timeState7 = false;
      }
    },




    postData(e) {
      this.$refs[e].validate((valid) => {
        if (valid) {
                const dataForm = new FormData();
      dataForm.append("title", localStorage.getItem("title"));
      dataForm.append("description", localStorage.getItem("description"));
      dataForm.append("type_id", localStorage.getItem("type_id"));
      dataForm.append("place_id", localStorage.getItem("place_id"));
      dataForm.append("color", localStorage.getItem("color"));
      dataForm.append("hour", 0);
      dataForm.append("mints", this.form.mints);
      dataForm.append("start_date", this.week);
      dataForm.append("end_date", this.form.end_date);
      dataForm.append("price", this.form.price);
      dataForm.append("payment_id", this.form.payment_id);
      dataForm.append("url", this.form.url);
      dataForm.append("blog_id", localStorage.getItem('ConsId'));


      if (this.day == true) {
        this.Days.push("Saturday");
      }
      if (this.day2 == true) {
        this.Days.push("Sunday");
      }
      if (this.day3 == true) {
        this.Days.push("Monday");
      }
      if (this.day4 == true) {
        this.Days.push("Tuesday");
      }

      if (this.day5 == true) {
        this.Days.push("Wednesday");
      }
      if (this.day6 == true) {
        this.Days.push("Thursday");
      }

      if (this.day7 == true) {
        this.Days.push("Friday");
      }

      if (this.timeState == false) {
        this.From.push(this.startTime);
        this.To.push(this.endTime);
      }

      if (this.timeState2 == false) {
        this.From.push(this.startTime2);
        this.To.push(this.endTime2);
      }

      if (this.timeState3 == false) {
        this.From.push(this.startTime3);
        this.To.push(this.endTime3);
      }

      if (this.timeState4 == false) {
        this.From.push(this.startTime4);
        this.To.push(this.endTime4);
      }

      if (this.timeState5 == false) {
        this.From.push(this.startTime5);
        this.To.push(this.endTime5);
      }

      if (this.timeState6 == false) {
        this.From.push(this.startTime6);
        this.To.push(this.endTime6);
      }

      if (this.timeState7 == false) {
        this.From.push(this.startTime7);
        this.To.push(this.endTime7);
      }

      this.satrday.forEach((item, index) => {
        if (item.status == true) {
          dataForm.append(`day[0]`, item.day);
          dataForm.append(`from[0]`, item.startSat);
          dataForm.append(`to[0]`, item.endSat);
        }
      });

      this.Sunday.forEach((item, index) => {
        if (item.status == true) {
          dataForm.append(`day[1]`, item.day);
          dataForm.append(`from[1]`, item.startSat);
          dataForm.append(`to[1]`, item.endSat);
        }
      });

      this.Monday.forEach((item, index) => {
        if (item.status == true) {
          dataForm.append(`day[2]`, item.day);
          dataForm.append(`from[2]`, item.startSat);
          dataForm.append(`to[2]`, item.endSat);
        }
      });

      this.Tuesday.forEach((item, index) => {
        if (item.status == true) {
          dataForm.append(`day[3]`, item.day);
          dataForm.append(`from[3]`, item.startSat);
          dataForm.append(`to[3]`, item.endSat);
        }
      });

      this.Wednesday.forEach((item, index) => {
        if (item.status == true) {
          dataForm.append(`day[4]`, item.day);
          dataForm.append(`from[4]`, item.startSat);
          dataForm.append(`to[4]`, item.endSat);
        }
      });

      this.Thursday.forEach((item, index) => {
        if (item.status == true) {
          dataForm.append(`day[5]`, item.day);
          dataForm.append(`from[5]`, item.startSat);
          dataForm.append(`to[5]`, item.endSat);
        }
      });

      this.Friday.forEach((item, index) => {
        if (item.status == true) {
          dataForm.append(`day[6]`, item.day);
          dataForm.append(`from[6]`, item.startSat);
          dataForm.append(`to[6]`, item.endSat);
        }
      });

      // this.From.forEach((item, index) => {
      //   dataForm.append(`from[${index}]`, item);
      // });

      // this.To.forEach((item, index) => {
      //   dataForm.append(`to[${index}]`, item);
      // });

      // this.Days.forEach((item, index) => {
      //   dataForm.append(`day[${index}]`, item);
      // });

      const ConsId =localStorage.getItem('ConsId')

      this.axios
        .post(
          `https://dashboard.arabicreators.com/api/update_consultion/${ConsId}`,
          dataForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.code == 400) {
            this.$message.error("حدث خطأ");
          } else if (res.data.code == 200) {
            this.$message({
              showClose: true,
              message: "تمت التعديل بنجاح، سيتم مراجعة طلبك خلال ٢٤ ساعة",
              type: "success",
            });
            localStorage.removeItem("title")
            localStorage.removeItem("description")
            this.$router.push("/Consulting");
            // this.$router.go();
          }
        });
        } else {
          console.log("error submit!!");
          return false;
        }
      });


    },

    checkUrl() {
      const userId = localStorage.getItem("userId");

      this.axios
        .get(
          `https://dashboard.arabicreators.com/api/check_url/${userId}/${this.form.url}`,

          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.data.message == "الاسم متاح") {
            this.urlMsg = res.data.message;
            this.urlMsg2 = null;
          } else if (res.data.message == "الرابط موجود بالفعل") {
            this.urlMsg2 = res.data.message;
            this.urlMsg = null;
          }
        });
    },
    onSubmit() {
      console.log("submit!");
    },
    perv() {
      this.$router.push("/CreateConsultation");
    },
  },
};
</script>

<style lang="scss">
.createConsultaion {
  font-family: "Tajawal", sans-serif;
  direction: rtl;
  background-color: #f0efef;
  padding-bottom: 50px;
  .content {
    padding-top: 50px;
    padding-bottom: 50px;
    .header {
      text-align: right;
      h2 {
        font-weight: 700;
      }
      p {
      }
    }
    .form {
      max-width: 100%;
      margin: auto;
      .el-form-item__label {
        display: block;
        text-align: right;
        padding-bottom: 0;
        font-weight: 700;
      }
      .el-select {
        width: 100%;
      }
      .el-input__inner {
        height: 50px;
        border-radius: 10px;
      }
      .el-button-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
      }
      .el-button-group > .el-button {
        border-radius: 50%;
        width: 50px;
        height: 50px;
      }
      .el-button--primary {
        display: block;
        width: 100%;
        background: var(--darkColor);
        border-radius: 10px;
        color: white;
        height: 50px;
        margin-bottom: 10px;
        margin-top: 40px;
        border: none;
      }
      .el-form-item__content {
        display: flex;
        // justify-content: space-between;
      }
      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100% !important;
      }
      .prev {
        display: block;
        width: 100%;
        border-radius: 10px;
        color: #fff !important;
        height: 50px;
        margin-bottom: 10px;
        margin-top: 40px;
        background-color: white;
        font-size: 20px !important;
      }
      .add-more {
        background-color: var(--darkColor);
        color: white;
        padding: 6px;
        display: block;
        margin-bottom: 20px;
      }
      .el-slider {
        margin-bottom: 30px;
      }
      .el-slider__bar {
        background-color: unset;
      }
      .el-slider__runway {
        background-color: #bfbfbf;
        height: 10px;
      }
      .el-slider__stop {
        height: 10px;
        width: 10px;
      }
      .el-slider__button {
        border-color: #fff;
        border-width: 3px;
        width: 23px;
        height: 23px;
      }
    }
  }
}
</style>


<style>
.slecetDates .el-input__inner {
  height: 40px !important;
}

.slecetDates span {
  text-align: start;
}

.tooltip-class {
  background-color: brown !important;
}

.el-tooltip__popper {
  width: 60px !important;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
}

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
  border: 3px solid #409eff !important;
  line-height: 46px;
  transition: unset !important;
}
</style>

<style lang="scss">
.cons2 {
  .el-checkbox__label {
    font-size: 15px !important;
  }
}

.addsTime {
  .el-form-item {
    margin-bottom: 0 !important;
  }
  .el-input__inner {
    width: 50%;
  }
}

.el-radio-button__inner {
  background-color: transparent !important;
  border: none !important;
  color: #000 !important;
  font-size: 22px !important;
  outline: unset !important;
  transition: unset !important;

  &:hover {
    transform: rotate(360deg);
  }
}

.slider .el-input__inner {
  height: unset !important;
  text-align: center !important;
}

.el-slider__input {
  // float: unset !important;
  // margin-top: 3px;
  width: 130px;
  display: inline-block;
  // position: relative !important;
  // right: 43% !important;
  // margin-bottom: 30px;
}

.el-slider__runway.show-input {
  // margin-right: unset !important;

  .el-input__inner {
  }
}

.el-input-number--small .el-input-number__decrease,
.el-input-number--small .el-input-number__increase {
  display: none;
}

.toll {
  display: block !important;
  opacity: 1;
  z-index: 50000 !important;
  width: 80px !important;
  height: 80px !important;
  border-radius: 50% !important;
  line-height: 60px !important;
  font-size: 18px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px !important;
  background-color: var(--darkColor) !important;
}

.week {
  width: 30% !important;
}

.link {
  .el-form-item__label {
    text-align: left !important;
    display: block !important;
    width: 100% !important;
  }

  .el-form-item__content {
    text-align: left !important;
  }

  .el-input__inner {
    text-align: left !important;
  }
}

.addTime {
  .el-input__inner {
    height: 40px !important;
  }
}

.addsTime {
  .el-form-item {
      width: 33% !important;
  }

  .el-input__inner {
    height: 40px !important;
    width: 100%;
  }
}

// .dis{
//   display: none;
// }
</style>