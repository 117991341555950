<template>
  <div class="createConsultaion">
    <!-- <navbar /> -->

    <div class="content">
      <div class="container">
        <div class="header">
          <h2>انشاء استشارة</h2>
          <p>من خلال إنشاء استشارة ، ستظهر في منصة Arabi marketer</p>
        </div>

        <div class="form">
          <el-form
            ref="form"
            :model="form"
            label-width="120px"
            label-position="top"
            :rules="rules"
          >
            <el-form-item label="عنوان الاستشارة " prop="title">
              <el-input
                v-model="form.title"
                placeholder="أدخل العنوان "
              ></el-input>
            </el-form-item>

            <el-form-item label="نوع الاستشارة " prop="type_id">
              <el-select multiple  v-model="form.type_id" placeholder="نوع الاستشارة ">
                <el-option
                  v-for="item in consultation_category"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
               
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="اختيار التطبيق المناسب" prop="place_id">
              <el-select v-model="form.place_id" placeholder="اختر">
                <el-option
                  v-for="item in consultation_places"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                   <span style="float: left">{{ item.title }}</span>
                   <img :src="item.image" style="width:30px;margin-left:10px;border-radius:50%;" alt="">
                    <span style="float: right; color: #8492a6; font-size: 13px" v-if="item.id ==1 || item.id ==2 "><img src="../assets/premium-quality.png" alt=""></span>
                </el-option>
              </el-select>
            </el-form-item>
             <span v-if="form.place_id==1 || form.place_id==2" class="text-end d-block text-danger" style="font-size:13px">هذا الخيار بريميوم</span>


            <el-form-item label="الوصف" prop="description">
              <editor
                api-key="lq78n5tuhbwnunb6moxdzq6d2cyx8o895bfzepnwbw3mc29e"
                :init="{
                  directionality: 'rtl',
                  language: 'ar',
                  height: 500,
                  menubar: 'false',
                  plugins: [
                    'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                  ],
                  toolbar:
                    'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                  toolbar_sticky: true,
                  image_caption: true,
                  toolbar_mode: 'sliding',
                }"
                v-model="form.description"
                style="width: 100%"
              />

              <!-- <el-input
                type="textarea"
                :autosize="{ minRows: 10, maxRows: 14 }"
                v-model="form.description"
              ></el-input> -->
            </el-form-item>



            <el-dialog
              :visible.sync="dialogVisible"
              width="70%"
              :before-close="handleClose"
              
            >

              <h2 :style="{ color: form.color }">عنوان الاستشاراة</h2>
              <span style="color:#a9a9a9;" :style="{ color: form.color }"><i class="el-icon-user-solid"></i> Mohamed Ali </span>

              <img src="../assets/girlPodcast.png" class="mb-1 mt-3 d-block" alt="" />
              <span class="d-inline-block mb-2 " :style="{ color: form.color }"><i class="el-icon-time ms-1"></i>25/1/2023</span>  
              <p>
                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
                هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو
                العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها
                التطبيق. إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص
                العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي
                أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه
                الخصوص، حيث يحتاج العميل فى كثير من الأحيان أن يطلع على صورة
                حقيقية لتصميم الموقع. ومن هنا وجب على المصمم أن يضع نصوصا مؤقتة
                على التصميم ليظهر للعميل الشكل كاملاً،دور مولد النص العربى أن
                يوفر على المصمم عناء البحث عن نص بديل لا علاقة له بالموضوع الذى
                يتحدث عنه التصميم فيظهر بشكل لا يليق. هذا النص يمكن أن يتم
                تركيبه على أي تصميم دون مشكلة فلن يبدو وكأنه نص منسوخ، غير منظم،
                غير منسق، أو حتى غير مفهوم. لأنه مازال نصاً بديلاً ومؤقتاً.

                  هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
                هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو
                العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها
                التطبيق. إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص
                العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي
                أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه
                الخصوص، حيث يحتاج العميل فى كثير من الأحيان أن يطلع على صورة
                حقيقية لتصميم الموقع. ومن هنا وجب على المصمم أن يضع نصوصا مؤقتة
                على التصميم ليظهر للعميل الشكل كاملاً،دور مولد النص العربى أن
                يوفر على المصمم عناء البحث عن نص بديل لا علاقة له بالموضوع الذى
                يتحدث عنه التصميم فيظهر بشكل لا يليق. هذا النص يمكن أن يتم
                تركيبه على أي تصميم دون مشكلة فلن يبدو وكأنه نص منسوخ، غير منظم،
                غير منسق، أو حتى غير مفهوم. لأنه مازال نصاً بديلاً ومؤقتاً.
              </p>

              <p class="mt-2">هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.
إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد</p>
            </el-dialog>

            
            <span class="text-end d-block fw-bold mb-3 mt-4">اختر القالب المناسب</span>

            <div class="colors">
              <el-radio-group v-model="form.color">
                <el-radio-button
                  label="#8a0000"
                  style="background: #8a0000"
                ></el-radio-button>
                <el-radio-button
                  label="#fd9010"
                  style="background: #fd9010"
                ></el-radio-button>
                <el-radio-button
                  label="#00508a"
                  style="background: #00508a"
                ></el-radio-button>
                <el-radio-button
                 label="#8a0074"
                  style="background: #8a0074"
                ></el-radio-button>
                <el-radio-button
                  label="#67b979"
                  style="background: #67b979"
                ></el-radio-button>
                <el-radio-button
                  label="#f3db04"
                  style="background: #f3db04"
                ></el-radio-button>
                <el-radio-button
                  label="#8a4200"
                  style="background: #8a4200"
                ></el-radio-button>
                <el-radio-button
                  label="#f5547b"
                  style="background: #f5547b"
                ></el-radio-button>
              </el-radio-group>
            </div>

              <p class="mb-0 mt-4">هذا الخيار لمساعدتك في تصنيف الاستشارات لديك</p>
              <a @click="dialogVisible = true" class=" d-inline-block"
              >لمعاينة القالب اضغط هنا
            </a>

            <el-button type="primary" @click="onSubmit('form')">التالي</el-button>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import navbar from "@/components/navComponentInner.vue";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    navbar,
    editor: Editor,
  },

  data() {
    return {
      dialogVisible: false,
      form: {
        title: localStorage.getItem("title"),
        description: localStorage.getItem("description"),
        type_id: [parseInt(localStorage.getItem('type_id'))],
        place_id: parseInt(localStorage.getItem('place_id')),
        color: "#f3db04",
      },

      rules: {
        title: [
          { required: true, message: "هذا الحقل مطلوب", trigger: "blur" },
        ],
        description: [
          { required: true, message: "هذا الحقل مطلوب", trigger: "blur" },
        ],
        type_id: [
          { required: true, message: "هذا الحقل مطلوب", trigger: "blur" },
        ],
        place_id: [
          { required: true, message: " هذا الحقل مطلوب ", trigger: "change" },
        ],
        color: [
          { required: true, message: " هذا الحقل مطلوب ", trigger: "change" },
        ],
      },

      consultation_category: null,
      consultation_places: null,
    };
  },

  mounted() {

    scrollTo(0,0)

      localStorage.setItem("title", '');
      localStorage.setItem("description", '');
      localStorage.setItem("type_id",parseInt('14'));
      localStorage.setItem("place_id", parseInt('3'));

    if (!localStorage.getItem("token")){
      this.$router.push("/signIn");
    }
    Vue.axios
      .get("https://dashboard.arabicreators.com/api/consultation_category")
      .then((rr) => {
        console.log(rr);
        this.consultation_category = rr.data.data;
      });

    Vue.axios
      .get("https://dashboard.arabicreators.com/api/consultation_places")
      .then((rr) => {
        console.log(rr);
        this.consultation_places = rr.data.data;
      });
  },

  methods: {
    onSubmit(e) {

            this.$refs[e].validate((valid) => {
        if (valid) {
      localStorage.setItem("title", this.form.title);
      localStorage.setItem("description", this.form.description);
      localStorage.setItem("type_id", this.form.type_id);
      localStorage.setItem("place_id", this.form.place_id);
      localStorage.setItem("color", this.form.color);
      this.$router.push("/CreateConsultationTwo");
      
        } else {
          window.scrollTo(280, 280);
          console.log("error submit!!");
          return false;
        }
      });

    },
  },
};
</script>

<style lang="scss">
.createConsultaion {
  font-family: "Tajawal", sans-serif;

  direction: rtl;
  background-color: #f0efef;
  padding-bottom: 50px;

  .content {
    padding-top: 50px;
    padding-bottom: 50px;

    .header {
      text-align: right;
      h2 {
        font-weight: 700;
      }

      p {
      }
    }

    .form {
      max-width: 100%;
      margin: auto;

      .el-form-item__label {
        display: block;
        text-align: right;
        padding-bottom: 0;
        font-weight: 700;
      }

      .el-select {
        width: 100%;
      }

      .el-input__inner {
        height: 50px;
        border-radius: 10px;
      }

      .el-button-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
      }

      .el-button-group > .el-button {
        border-radius: 50%;
        width: 50px;
        height: 50px;
      }

      .el-button--primary {
        display: block;
        width: 100%;
        background: linear-gradient(
          239.11deg,
          #70d644 -31.67%,
          #567a46 192.97%
        );
        border-radius: 10px;
        color: white;
        height: 50px;
        margin-bottom: 10px;
        margin-top: 40px;
        border: none;
      }
    }
  }
}
</style>


<style lang="scss">
.el-dialog__body {
  padding: 20px !important;
}

.colors{
  height: 90px;
}

.form .el-radio-group{
  height: 90px;
  display: flex !important;
}

.el-radio-button {
  position: relative;
  display: inline-block;
  outline: 0;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  border: none !important;
  margin: 10px;
  cursor: pointer;
  text-align: center;
  line-height: 75px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  outline: unset !important;
  transition: unset;

  &:hover {
    transform: rotate(360deg);
    border: 2px solid #409eff !important;
  }

  // &:hover .el-radio-button__inner {
  //   font-weight: 500 !important;
  // }
}


.el-radio-button__orig-radio:checked+.el-radio-button__inner{
    width: 100%;
    height: 100%;
    border-radius: 50% !important;
    border: 3px solid #409eff !important;
    line-height: 46px;
    transition: unset !important;
}

.colors {.el-radio-button__inner {
  background-color: transparent !important;
  border: none !important;
  color: rgb(255, 255, 255) !important;
  font-size: 12px !important;
  opacity: 1 !important;
    outline: unset !important;
        transition: unset !important;
  font-size: 0px !important;

      &:hover {
    transform: rotate(360deg);
  }

}}

label{
  outline: unset !important;
  border: none !important;
}

// .is-active{
//   transition: all .3s;
//   border: 4px solid #8fc5e6;
// }
</style>