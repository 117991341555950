<template>
  <div class="createConsultaion">
    <!-- <navbar /> -->

    <div class="content">
      <div class="container">
        <div class="header">
          <h2>انشاء استشارة</h2>
          <p>من خلال إنشاء استشارة ، ستظهر في منصة Arabi marketer</p>
        </div>

        <div class="form">
          <el-form
            ref="form"
            :model="form"
            label-width="120px"
            label-position="top"
            :rules="rules"
            
          >
            <el-form-item label="الساعات المتاحة" prop="dateItem">
              <el-time-select
    placeholder="من"
    v-model="startTime"
    :picker-options="{
      start: '08:00',
      step: '00:30',
      end: '20:00'
    }">
  </el-time-select>
  <el-time-select
    placeholder="الى"
    v-model="endTime"
    :picker-options="{
      start: '08:00',
      step: '00:30',
      end: '20:00',
      minTime: startTime
    }">
  </el-time-select>
            </el-form-item>


            <el-form-item label="الايام المتاحة" prop="day">

                  <div class="days d-flex me-2">
                    <div  class="CheckItem">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name=""
                    @click="getID($event)"
                    id="Satarday"
                  />

                <label class="form-check-label" for="Satarday">السبت</label>

                </div>

                <div  class="CheckItem">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    @click="getID($event)"
                    name=""
                    id="Sunday"
                  />

                <label class="form-check-label" for="Sunday">الاحد</label>

                </div>

                 <div  class="CheckItem">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    @click="getID($event)"
                    name=""
                    id="Monday"
                  />

                <label class="form-check-label" for="Monday">الاثنين</label>

                </div>


                                 <div  class="CheckItem">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    @click="getID($event)"
                    name=""
                    id="Tuesday"
                  />

                <label class="form-check-label" for="Tuesday">الثلاثاء</label>

                </div>


                                 <div  class="CheckItem">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    @click="getID($event)"
                    name=""
                    id="Wednesday"
                  />

                <label class="form-check-label" for="Wednesday">الاربعاء</label>

                </div>

                                 <div  class="CheckItem">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    @click="getID($event)"
                    name=""
                    id="Thursday"
                  />

                <label class="form-check-label" for="Thursday">الخميس</label>

                </div>

                                 <div  class="CheckItem">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    @click="getID($event)"
                    name=""
                    id="Friday"
                  />

                <label class="form-check-label" for="Friday">الجمعة</label>

                </div>


                  </div>



            </el-form-item>

     


            <el-button type="primary" @click="setDefultData('form')">ارسل</el-button>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import navbar from "@/components/navComponentInner.vue";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    navbar,
    editor: Editor,
  },

  data() {
    return {
      dialogVisible: false,
      form: {
        title: localStorage.getItem("title"),
        description: localStorage.getItem("description"),
        type_id: [],
        place_id: '',
        color: "#f3db04",
      },

      Days:[],
        startTime: '',
        endTime: '',

      consultation_category: null,
      consultation_places: null,
        rules: {
        dateItem:[
          { required: true, message: "هذا الحقل مطلوب", trigger: "blur" },
        ],  
        day:[
          { required: true, message: " هذا الحقل مطلوب ", trigger: "change" },
        ],
      },
    };
  },

  mounted() {


    if(!localStorage.getItem("token")){
      this.$router.push("/signIn");
    }
   
  },

  methods: {
    getID(event) {

      if (event.target.checked) {
        this.Days.push(event.target.id);
      } else {
        const id = event.target.id;
        for (let data of this.Days) {
          if (data === id) {
            const index = this.Days.indexOf(data);
            this.Days.splice(index, 1);
          }
        }
      }

      
      console.log(JSON.stringify(this.Days))

      
    },


    setDefultData(form){

      this.$refs[form].validate((valid) => {
        if (valid) {
        } else {
          return false;
        }
      });

      const dataForm = new FormData();

            dataForm.append("start_at", this.startTime);
            dataForm.append("end_at", this.endTime);
            dataForm.append("days", JSON.stringify(this.Days));

            this.axios
        .post(
          "https://dashboard.arabicreators.com/api/edit_con_value",
          dataForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.data.code == 200) {
               this.$message({
              message: "تم الحفظ بنجاح",
              type: "success",
            });

            this.$router.push("/Consulting");

          }
        })
    }

  },
};
</script>

<style lang="scss">
.createConsultaion {
  font-family: "Tajawal", sans-serif;

  direction: rtl;
  background-color: #f0efef;
  padding-bottom: 50px;

  .content {
    padding-top: 50px;
    padding-bottom: 50px;

    .header {
      text-align: right;
      h2 {
        font-weight: 700;
      }

      p {
      }
    }

    .form {
      max-width: 100%;
      margin: auto;

      .el-form-item__label {
        display: block;
        text-align: right;
        padding-bottom: 0;
        font-weight: 700;
      }

      .el-select {
        width: 100%;
      }

      .el-input__inner {
        height: 50px;
        border-radius: 10px;
      }

      .el-button-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
      }

      .el-button-group > .el-button {
        border-radius: 50%;
        width: 50px;
        height: 50px;
      }

      .el-button--primary {
        display: block;
        width: 100%;
        background: linear-gradient(
          239.11deg,
          #70d644 -31.67%,
          #567a46 192.97%
        );
        border-radius: 10px;
        color: white;
        height: 50px;
        margin-bottom: 10px;
        margin-top: 40px;
        border: none;
      }
    }
  }


  .days{

    border: 1px solid #dfdfdf;
    border-radius: 10px;


      .CheckItem{
    height: 70px;
    width: 125.5px;
    text-align: center;
    border-left: 1px solid #dfdfdf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label{
      font-size: 16px;
      font-weight: 600;
    }

    .form-check-input{
      width: 1.2em;
      height: 1.2em;
      margin-top: 1.1em;
    }
  }

  .CheckItem:last-child{
    border: unset !important;
  }
  }


}
</style>

